// TODO: Migrate to styled components
/* #region Inline input */
.react-datepicker-wrapper {
  width: 100%;

  .react-datepicker__input-container {
    color: black;

    .inline-date-picker {
      width: 100%;
      color: var(--gray-1);
      border-radius: 5px;
      border: 1px solid var(--gray-1);
      padding: 0.3rem 0.5rem;

      &.disabled {
        cursor: default;
      }
    }
  }
}

/* #endregion */

/* #region Calendar popup */
.react-datepicker.custom-calendar-modal {
  --calendar-cell-size: 30px;
  --container-padding: 10px;
  --top-padding: 20px;

  border: 0px;
  box-shadow: 0px 6px 5px -2px #0000001A;
  box-shadow: 0px 12px 10px -3px #00000040;

  .react-datepicker__triangle {

    &::before,
    &::after {
      display: none;
    }
  }

  .react-datepicker__month-container {
    background-color: var(--background-black-3);
    float: unset;
  }

  .react-datepicker__header {
    padding: var(--top-padding) var(--container-padding) 8px;
    background-color: var(--background-black-3);
    border: 1px solid var(--gray-2);
    border-bottom: 0px;
  }

  .react-datepicker__current-month {
    color: var(--font-white-3) !important;
    margin-bottom: calc(var(--top-padding)/2);
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    color: var(--font-white-3);
    width: var(--calendar-cell-size);
  }

  .react-datepicker__navigation {
    top: var(--top-padding);

    &:hover *::before{
        border-color: #ABABAE;
      }
  }

  .react-datepicker__navigation-icon::before {
    width: 8px;
    height: 8px;

    
  }

  .react-datepicker__navigation--next {
    right: var(--container-padding);
  }

  .react-datepicker__navigation--previous {
    left: var(--container-padding);
  }

  .react-datepicker__month {
    margin: 0px;
    padding-bottom: var(--container-padding);
    border: 1px solid var(--gray-2);
    border-top: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .react-datepicker__day {
    color: var(--font-white-3);
    width: var(--calendar-cell-size);
    height: var(--calendar-cell-size);
    border-radius: 25px;
    padding-top: 1px;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: unset;
  }

  .react-datepicker__day {
    &--disabled {
      color: var(--gray-3);
    }

    &:not(.react-datepicker__day--disabled) {

      &.react-datepicker__day:hover,
      &.react-datepicker__day--selected {
        background-color: var(--red-1);
      }
    }
  }

  .react-datepicker__day--keyboard-selected:not(hover) {
    background-color: unset;
  }
}

/* #endregion */