*:focus {
  box-shadow: none !important;
}

html {
  height: 100%;
}

html>body {
  height: 100%;
}

html>body>div#root {
  height: 100%;
}

@media (max-width: 500px) {
  :root {
    font-size: 14px;
  }
}

@media (min-width: 500px) {
  :root {
    font-size: 16px;
  }
}

:root {

  --font-white: #DBDCDF;
  --font-white-3: #F3F3F3;

  --gray-1: #94A3B8;
  --gray-2: #98A2B2;
  --gray-3: #4C4E61;
  --gray-4: #333441;
  --gray-5: #353646;

  --green-1: #2AA44D;
  --green-2: #68e28b;

  --red-1: #E95E5E;
  --red-2: #eb8181;

  --blue-1: #279CE6;
  --blue-2: #3182CE;
  --blue-3: #625BF6;

  --background-black-1: #181B23;
  --background-black-2: #2B2C37;
  --background-black-3: #212122;

}

.justify-content-between {
  justify-content: space-between;
}

.w-100{
  width: 100%;
}

/* #region Input */
input:focus-visible {
  outline: none;
}

/* #endregion */

/* #region Hide scrollbar */
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* #endregion */

/* #region Scrollbar */
.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
    height: 30px
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: var(--background-black-1);

  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #333441;
    border-radius: 5px;
    border: 2px solid var(--background-black-1);
  }
}

/* #endregion */

/* #region Font */
@font-face {
  font-family: "gilroy";
  src: url("/public/fonts/gilroy/Gilroy-Light.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "gilroy";
  src: url("/public/fonts/gilroy/Gilroy-Medium.ttf");
  font-style: medium;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "gilroy";
  src: url("/public/fonts/gilroy/Gilroy-Bold.ttf");
  font-style: medium;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "gilroy";
  src: url("/public/fonts/gilroy/Gilroy-Heavy.ttf");
  font-style: medium;
  font-weight: 700;
  font-display: swap;
}

/* #endregion */

/* #region React tabs */
.react-tabs__tab:focus:after {
  content: none !important;
}
/* #endregion */